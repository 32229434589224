import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import PaginationControl from "../../components/Table/PaginationControl";
import TableHeader from "../../components/Table/TableHeader";
import TableRow from "../../components/Table/TableRow";
import Avatar from "../../components/Images/Avatar";
import { IoIosArrowDown } from "react-icons/io";
import { BiSearch } from "react-icons/bi";
import useDebouncedSearchInput from "../../hooks/useDebouncedSearch";
import { FaExclamationTriangle, FaStar } from "react-icons/fa";

const Users = () => {
  const navigate = useNavigate();
  const [searchInput, setSearchInput] = useState("");
  const [createdBy, setCreatedBy] = useState("");
  const [searchSetting, setSearchSetting] = useState({
    page: 0,
    size: 10,
    sortBy: "createdAt",
    sortDirection: "DESC",
    searchPattern: "",
    status: "active",
    userId: "",
    city: "",
    tag: [],
    onlyWithReports: false,
    countryCode: "",
  });
  const { data, loading } = useFetch(
    `/v1/admin/places?page=${searchSetting.page}&pageSize=${searchSetting.size}&sortBy=${searchSetting.sortBy}&order=${searchSetting.sortDirection}&status=${searchSetting.status}&searchPattern=${searchSetting.searchPattern}&onlyWithReports=${searchSetting.onlyWithReports}&userId=${searchSetting.userId}&city=${searchSetting.city}&countryCode=${searchSetting.countryCode}&tag=${searchSetting.tag}`,
  );

  const { data: countries, loading: countryLoading } = useFetch(
    `/v2/countries/places?pageSize=100`,
  );

  const { data: tags, loading: tagsLoading } = useFetch("/v1/tags");
  const { data: cities, loading: citiesLoading } = useFetch("/v1/cities");

  useDebouncedSearchInput(searchInput, setSearchSetting, "searchPattern");
  useDebouncedSearchInput(createdBy, setSearchSetting, "userId");

  const handleSearchSettingsChange = (event) => {
    const { name, value } = event.target;
    setSearchSetting((prevEntity) => ({
      ...prevEntity,
      [name]: value,
      page: 0,
    }));
  };

  return (
    <div>
      <div className="flex justify-between mb-6 items-center">
        <span className="font-bold text-lg">PLACES</span>
      </div>
      <div className="relative mb-6">
        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
          <BiSearch className="text-black" />
        </div>
        <input
          type="search"
          onChange={(event) => setSearchInput(event.target.value)}
          className="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:outline-none shadow-sm"
          placeholder="Search place..."
        />
      </div>
      {loading || countryLoading || citiesLoading || tagsLoading ? (
        <span>Loading...</span>
      ) : (
        <div className="bg-white p-6 rounded-md shadow-md">
          <div className="grid justify-start grid-cols-1 xl:grid-cols-4 lg:grid-cols-2 gap-x-8 gap-2 mb-6">
            <div className="relative">
              <label className="relative text-[10px] text-gray-700">
                Country
                <select
                  name="countries"
                  defaultValue=""
                  onChange={(event) => {
                    setSearchSetting((prevState) => ({
                      ...prevState,
                      countryCode: event.target.value,
                    }));
                  }}
                  className="custom-select"
                >
                  <option value="">All</option>
                  {countries.data.map((country, i) => (
                    <option key={i} value={country.code}>
                      {country.name}
                    </option>
                  ))}
                </select>
                <IoIosArrowDown className="text-black absolute right-2.5 bottom-2.5 hover:cursor-pointer" />
              </label>
            </div>
            <div className="relative">
              <label className="relative text-[10px] text-gray-700">
                City
                <select
                  name="city"
                  defaultValue=""
                  onChange={(event) => {
                    setSearchSetting((prevState) => ({
                      ...prevState,
                      city: event.target.value,
                    }));
                  }}
                  className="custom-select"
                >
                  <option value="">All</option>
                  {cities.map((city, i) => (
                    <option key={i} value={city.name}>
                      {city.name}
                    </option>
                  ))}
                </select>
                <IoIosArrowDown className="text-black absolute right-2.5 bottom-2.5 hover:cursor-pointer" />
              </label>
            </div>
            <div className="relative">
              <label className="relative text-[10px] text-gray-700">
                Tag
                <select
                  name="tag"
                  defaultValue=""
                  onChange={(event) => {
                    setSearchSetting((prevState) => ({
                      ...prevState,
                      tag: event.target.value,
                    }));
                  }}
                  className="custom-select"
                >
                  <option value="">All</option>
                  {tags.map((tag, i) => (
                    <option key={i} value={tag.id}>
                      {tag.name}
                    </option>
                  ))}
                </select>
                <IoIosArrowDown className="text-black absolute right-2.5 bottom-2.5 hover:cursor-pointer" />
              </label>
            </div>
            <div className="relative">
              <label className="relative text-[10px] text-gray-700">
                Status
                <select
                  name="status"
                  defaultValue="active"
                  onChange={handleSearchSettingsChange}
                  className="custom-select"
                >
                  <option value="">All</option>
                  <option value="active">Active</option>
                  <option value="blocked">Blocked</option>
                </select>
                <IoIosArrowDown className="text-black absolute right-2.5 bottom-2.5 hover:cursor-pointer" />
              </label>
            </div>
            <div className="relative">
              <label className="relative text-[10px] text-gray-700">
                Created By
                <input
                  type="text"
                  name="userId"
                  onChange={(event) => setCreatedBy(event.target.value)}
                  className="custom-input"
                  placeholder="User id"
                />
              </label>
            </div>
            <div className="relative">
              <label className="relative text-[10px] text-gray-700">
                Sort by
                <select
                  id="model"
                  name="sortBy"
                  defaultValue="createdAt"
                  onChange={handleSearchSettingsChange}
                  className="custom-select"
                >
                  <option value="createdAt">Recently created</option>
                  <option value="updatedAt">Recently updated</option>
                  <option value="rating">Rating</option>
                </select>
                <IoIosArrowDown className="text-black absolute right-2.5 bottom-2.5 hover:cursor-pointer" />
              </label>
            </div>
            <div className="relative">
              <label className="relative text-[10px] text-gray-700">
                Order by
                <select
                  name="sortDirection"
                  defaultValue="DESC"
                  onChange={handleSearchSettingsChange}
                  className="custom-select"
                >
                  <option value="DESC">Descending</option>
                  <option value="ASC">Ascending</option>
                </select>
                <IoIosArrowDown className="text-black absolute right-2.5 bottom-2.5 hover:cursor-pointer" />
              </label>
            </div>
            <div className="items-center flex gap-4 md:gap-6 pt-5">
              <div className="flex gap-2 items-center">
                <input
                  onChange={() =>
                    setSearchSetting({
                      ...searchSetting,
                      onlyWithReports: !searchSetting.onlyWithReports,
                    })
                  }
                  checked={searchSetting.onlyWithReports}
                  type="checkbox"
                  className="custom-checkbox"
                />
                <span className="text-sm">Only with claims</span>
              </div>
            </div>
          </div>
          <div className="mb-6">
            <table className="w-full">
              <thead className="text-gray-700 text-xs bg-gray-100 ">
                <tr>
                  <TableHeader name="ID" />
                  <TableHeader name="NAME" />
                  <TableHeader name="STATUS" />
                  <TableHeader name="COUNTRY" />
                  <TableHeader name="CITY" />
                  <TableHeader name="CLAIMS" />
                  <TableHeader name="RATING" />
                  <TableHeader name="BUSINESS" />
                  <TableHeader name="REVIEWS" />
                </tr>
              </thead>
              <tbody>
                {data.data.map((place, i) => (
                  <tr
                    key={i}
                    onClick={() => navigate(`/places/${place.id}`)}
                    className="border-b text-xs hover:bg-gray-50 duration-300 hover:cursor-pointer"
                  >
                    <TableRow value={place.id} />
                    <TableRow
                      value={
                        <div className="flex items-center gap-2">
                          <Avatar image={place.photos[0]} />
                          <div>{place.name}</div>
                        </div>
                      }
                    />
                    <TableRow
                      value={
                        <div className="flex items-center gap-2">
                          <span
                            className={`p-1 rounded-full ${place.isBlocked ? "bg-yellow-500" : "bg-lime-500"}`}
                          />
                          {place.isBlocked ? "Blocked" : "Active"}
                        </div>
                      }
                    />
                    <TableRow value={place.country} />
                    <TableRow value={place.city} />
                    <TableRow
                      value={
                        place.reports.length !== 0 && (
                          <div className="flex gap-1 items-center">
                            <FaExclamationTriangle className="text-red-600" />
                            {place.reports.length}
                          </div>
                        )
                      }
                    />
                    <TableRow
                      value={
                        <div className="flex items-center gap-2">
                          {[...Array(5)].map((_, index) => (
                            <span
                              key={index}
                              className={`${place.rating > index ? "text-yellow-500" : "text-gray-300"}`}
                            >
                              <FaStar />
                            </span>
                          ))}
                        </div>
                      }
                    />
                    <TableRow
                      value={<div>{place.businessOwner && "YES"}</div>}
                    />
                    <TableRow value={place.reviewCount} />
                  </tr>
                ))}
              </tbody>
            </table>
            <PaginationControl
              searchSetting={searchSetting}
              setSearchSetting={setSearchSetting}
              paginationSettings={data.pageMetadata}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Users;
